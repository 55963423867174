<template>
  <el-breadcrumb separator-class="el-icon-arrow-right" style="margin-bottom: 30px;">
    <el-breadcrumb-item :to="{ path: '/BannerManagement' }">广告管理</el-breadcrumb-item>
    <el-breadcrumb-item>老带新详情</el-breadcrumb-item>
  </el-breadcrumb>
  <div>
    <!-- 1.筛选条件 -->
    <div class="select-box">
      <div class="search">
        <div style="width: 80px;">会员：</div>
        <el-input v-model="option.keyword" clearable placeholder="请输入手机号/名称/ID" @change="clearFun"></el-input>
      </div>
      <div style="margin-left: 30px;">
        <el-button @click="getTabList" type="primary">查 询</el-button>
      </div>
      <div style="margin-left: 30px;">
        <el-button @click="exportData" type="primary" plain>导 出</el-button>
      </div>
    </div>
    <!-- 2. table -->
    <el-table border stripe :data="data.table" style="width: 100%">
      <el-table-column type="index" width="50"></el-table-column>
      <el-table-column prop="newMemberId" label="会员ID" width="200"></el-table-column>
      <el-table-column prop="newMemberName" label="会员名称" width="200"></el-table-column>
      <el-table-column prop="newMemberPhone" label="手机号" width="120"></el-table-column>
      <el-table-column prop="oldMemberId" label="邀请人ID" width="200"></el-table-column>
      <el-table-column prop="oldMemberName" label="邀请人会员名称" width="120"></el-table-column>
      <el-table-column prop="oldMemberPhone" label="邀请人手机号" width="120"></el-table-column>
      <el-table-column prop="oldCouponNames" label="发放优惠券" show-overflow-tooltip></el-table-column>
      <el-table-column prop="creationTimeString" label="发放时间" width="200"></el-table-column>
      <el-table-column label="类型" width="100">
        <template #default="scope">
          <span v-if="scope.row.bindType == 1">邀请好友</span>
          <span v-else-if="scope.row.bindType == 2">绑定</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column prop="staffName" label="绑定人" width="120"></el-table-column>
    </el-table>
    <!-- 3.分页 -->
    <el-pagination
      class="fenye"
      @current-change="handleCurrentChange"
      :current-page="option.page"
      :page-size="10"
      prev-text="&nbsp;&nbsp;&nbsp;上一页&nbsp;&nbsp;&nbsp;"
      next-text="&nbsp;&nbsp;&nbsp;下一页&nbsp;&nbsp;&nbsp;"
      background
      layout="total, prev, pager, next, jumper"
      :total="data.total"
    >
    </el-pagination>
  </div>
</template>

<!-- 本页的js -->
<script>
import { ref, reactive, onMounted } from "vue";
import { ElMessage } from "element-plus";
import * as XLSX from "xlsx"
import { FullTimeFormat } from "../utils/common";
import { useRoute, useRouter } from "vue-router";
import { getBindRecordListAsyncList, bindRecordExport } from "../http/bannermanagement";
export default {
  name: "ApplicationProject",
  setup() {
    const route = useRoute();
    const router = useRouter();
    let bannerManagementId = route.query.bannerManagementId;
    onMounted(async () => {
      if (bannerManagementId == null || bannerManagementId.trim().length == 0) {
        router.push({ name: "BannerManagement" });
      }
    });
    let data = reactive({
      table: [],
      pages: 0,
      total: 0
    });
    // 声明查询条件
    let option = reactive({
      memberId: "",
      keyword: "",
      bannerManagementId: bannerManagementId,
      page: 1,
      pageSize: 10
    });
    let indexMethod = (index) => {
      return index + (option.page - 1) * option.pageSize + 1;
    };
    let clearFun = () => {
      let obj = {
        memberId: "",
        keyword: "",
        bannerManagementId: bannerManagementId,
        page: 1,
        pageSize: 10
      }
      getBindRecordListAsyncListFunFun(option);
    }
    let getBindRecordListAsyncListFunFun = async (obj) => {
      let arr = await getBindRecordListAsyncList(obj);
      data.table = arr.items;
      data.total = arr.totalCount
    };
    // 初始化时，获取初始化数据
    getBindRecordListAsyncListFunFun({
      Page: 1,
      PageSize: 10,
      bannerManagementId: bannerManagementId
    });

    // 点击页码获取数据
    let handleCurrentChange = (index) => {
      option.page = index;
      getBindRecordListAsyncListFunFun(option);
    };
    let getTabList = async () => {
      option.page = 1
      await getBindRecordListAsyncListFunFun(option);
    };
    
    // 导出表格为excel
    let exportData = () => {
      let data = {
        keyword: option.keyword,
        bannerManagementId: option.bannerManagementId
      }
      bindRecordExport(data).then((res) => {
        if(!res.eValues) return ElMessage.warning({
          message: '导出数据为空，请重新进行数据筛选',
          duration: 2000
        })
        let reg = /\\/g
        let replaceAfter = res.eValues.replace(reg, "")
        var jsondata = JSON.parse(replaceAfter)
        let time = new Date().getTime()
        const workbook = XLSX.utils.book_new()
        const worksheet = XLSX.utils.json_to_sheet(jsondata)
        XLSX.utils.book_append_sheet(workbook, worksheet, "data")
        XLSX.writeFile(workbook, `老带新发券列表_${time}.xlsx`)
      })
    }
    return {
      data,
      handleCurrentChange,
      getTabList,
      option,
      indexMethod,
      exportData,
      clearFun
    };
  }
};
</script>
<!-- 本页的css -->
<style lang="scss" scoped>
.select-box {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  .search {
    display: flex;
    align-items: center;
  }
}
.fenye {
  margin-top: 20px;
}
</style>
